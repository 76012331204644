import React from "react";
import { Icon } from "~/components/ui/icons";
import { ButtonLink } from "~/components/ui/button";
import clsx from "clsx";
import { useSharedContent } from "~/hooks/localization";
import { Dictionary } from "~/utils/language";
import { useRootData } from "~/hooks/common";
import { getUrl } from "~/utils/misc";
import { spTrackWebInteraction } from "~/utils/tracking";
import type { SimplePortableText } from "~/types/sanity-schema";
import { H2, Paragraph } from "~/components/ui/typography";
import { BlockContent } from "~/components/block-content";

type Props = {
	tagline?: string;
	title?: string;
	subtitle?: SimplePortableText;
	shareTitle?: string;
	currentUrl: string;
	variant?: "default" | "button";
	disableLinkedinShare?: boolean;
	disableFacebookShare?: boolean;
	disableTwitterShare?: boolean;
	disableEmailShare?: boolean;
};

export function SharingLinks({
	tagline,
	title,
	subtitle,
	shareTitle,
	currentUrl,
	variant = "default",
	disableLinkedinShare = false,
	disableFacebookShare = false,
	disableTwitterShare = false,
	disableEmailShare = false,
}: Props) {
	const { requestInfo } = useRootData();
	const currentPageUrl = getUrl(requestInfo);

	const { t } = useSharedContent(Dictionary.COMMON);

	const encodedTitle = shareTitle ? encodeURIComponent(shareTitle) : "";
	const shareUrl = currentUrl ? currentUrl : currentPageUrl;
	const encodedShareUrl = encodeURIComponent(shareUrl);

	const mailBody = `Check out this page ${shareUrl}`;
	const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodedShareUrl}`;
	const linkedinUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodedShareUrl}`;
	const twitterUrl = `https://twitter.com/intent/tweet?text=${encodedTitle}&url=${encodedShareUrl}`;

	function handleShareClick(e: React.MouseEvent<HTMLAnchorElement>) {
		e.preventDefault();

		spTrackWebInteraction({
			object: "social media",
			action: "share",
			value: e.currentTarget.title,
		});

		window.open(
			e.currentTarget.href,
			"pop-up",
			"left=1000,top=400,width=500,height=500,toolbar=1,resizable=0"
		);
	}

	function handleShareEmailClick() {
		spTrackWebInteraction({
			object: "social media",
			action: "share",
			value: "Share via email",
		});
	}

	return (
		<>
			{tagline || title ? (
				<div className="mx-auto mb-6 max-w-content text-center">
					{tagline ? (
						<Paragraph
							size="overline"
							className="mb-5"
							color="tagline"
						>
							{tagline}
						</Paragraph>
					) : null}
					{title ? <H2 className="mb-5">{title}</H2> : null}
					{subtitle ? <BlockContent value={subtitle} /> : null}
				</div>
			) : null}
			<ul
				className={clsx("flex items-center", {
					"flex-col justify-center gap-5 sm:flex-row":
						variant === "button",
					"flex-row gap-4": variant === "default",
				})}
			>
				{!disableLinkedinShare ? (
					<li>
						{variant === "button" ? (
							<ButtonLink
								onClick={handleShareClick}
								title="Share on LinkedIn"
								variant="secondary"
								to={linkedinUrl}
								iconLeft={
									<Icon
										name="linkedin"
										width="20"
										height="20"
									/>
								}
							>
								{t("post")}
							</ButtonLink>
						) : (
							<a
								href={linkedinUrl}
								title="Share on LinkedIn"
								onClick={handleShareClick}
							>
								<Icon
									name="linkedinWithBg"
									width="20"
									height="20"
								/>
							</a>
						)}
					</li>
				) : null}
				{!disableFacebookShare ? (
					<li>
						{variant === "button" ? (
							<ButtonLink
								onClick={handleShareClick}
								title="Share on Facebook"
								variant="secondary"
								to={facebookUrl}
								iconLeft={
									<Icon
										name="facebook"
										width="20"
										height="20"
									/>
								}
							>
								{t("share")}
							</ButtonLink>
						) : (
							<a
								href={facebookUrl}
								title="Share on Facebook"
								onClick={handleShareClick}
							>
								<Icon
									name={"facebookWithBg"}
									width="20"
									height="20"
								/>
							</a>
						)}
					</li>
				) : null}
				{!disableTwitterShare ? (
					<li>
						{variant === "button" ? (
							<ButtonLink
								onClick={handleShareClick}
								title="Share on Twitter"
								variant="secondary"
								to={twitterUrl}
								iconLeft={<Icon name="twitter" />}
							>
								{t("tweet")}
							</ButtonLink>
						) : (
							<a
								href={twitterUrl}
								title="Share on Twitter"
								onClick={handleShareClick}
							>
								<Icon
									name="twitterWithBg"
									width="20"
									height="20"
								/>
							</a>
						)}
					</li>
				) : null}
				{!disableEmailShare ? (
					<li>
						{variant === "button" ? (
							<ButtonLink
								variant="secondary"
								to={`mailto:?subject=${encodedTitle}&body=${mailBody}`}
								iconLeft={
									<Icon
										name="envelope"
										width="20"
										height="20"
									/>
								}
								onClick={() => handleShareEmailClick()}
							>
								{t("email")}
							</ButtonLink>
						) : (
							<a
								href={`mailto:?subject=${encodedTitle}&body=${mailBody}`}
								title="Share via Email"
								onClick={() => handleShareEmailClick()}
							>
								<Icon
									name="envelopeWithBg"
									width="20"
									height="20"
								/>
							</a>
						)}
					</li>
				) : null}
			</ul>
		</>
	);
}
